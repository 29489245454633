import { Container, Heading, Tag, Flex } from '@chakra-ui/react';

export default function Header() {
	return (
		<Container as="header">
			<Flex direction="column" align="center">
				{ process.env.NEXT_PUBLIC_IS_TESTING === 'true' && (
					<Tag size="md" variant="solid" colorScheme="orange">
						Testumgebung
					</Tag>
				) }
				<Heading size="xl" fontWeight="light" mb="4">
					Kaufleuten Scanner
				</Heading>
			</Flex>
		</Container>
	);
}
