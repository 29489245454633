import NextLink from 'next/link';
import dayjs from 'dayjs';
import {
	Box,
	Divider,
	Flex,
	Heading,
	LinkBox,
	LinkOverlay,
	SimpleGrid,
	Skeleton,
	Tag,
	Text,
	useColorModeValue,
} from '@chakra-ui/react';
import useEvents from '../hooks/useEvents';

export default function EventSelector() {
	const { events, isLoading } = useEvents();
	const bg = useColorModeValue( 'white', 'gray.700' );
	const bgHover = useColorModeValue( 'gray.100', 'gray.600' );

	return (
		<>
			<Text mb="2">Event zum Scannen auswählen:</Text>
			<SimpleGrid minChildWidth="200px" spacing="4">
				{ isLoading &&
					[ ...Array( 8 ) ].map( ( e, i ) => (
						<LinkBox
							key={ i }
							maxW={ [ 'full', 'sm' ] }
							p={ [ 2, 3, 4 ] }
							bg={ bg }
							borderWidth="1px"
							shadow="base"
							rounded="lg"
							transitionProperty="common"
							transitionDuration="normal"
							_hover={ {
								background: bgHover,
							} }
						>
							<Skeleton height="18px" />

							<Heading size="md" mt="2">
								<Skeleton height={ [ '25px', '50px' ] } />
							</Heading>
						</LinkBox>
					) ) }
				{ ! isLoading && !! events?.today?.length && (
					<>
						{ events.today.map( ( { id, title, eventTime, category } ) => {
							const date = dayjs.unix( eventTime );
							return (
								<LinkBox
									key={ id }
									maxW={ [ 'full', 'sm' ] }
									p={ [ 2, 3, 4 ] }
									bg={ bg }
									borderWidth="1px"
									shadow="base"
									rounded="lg"
									transitionProperty="common"
									transitionDuration="normal"
									_hover={ {
										background: bgHover,
									} }
								>
									<Flex fontSize="xs" alignItems="center">
										{ date.format( 'DD.MM.YYYY' ) } &middot; { category }
										<Tag ml="2" size="sm" colorScheme="green">
											Heute
										</Tag>
									</Flex>

									<Heading size="md" mt="2">
										<LinkOverlay
											as={ NextLink }
											href={ `/event/${ id }` }
											passHref
										>
											{ title }
										</LinkOverlay>
									</Heading>
								</LinkBox>
							);
						} ) }
						<Box gridColumn="1 / -1">
							<Divider my={ [ 1, 2 ] } />
						</Box>
					</>
				) }
				{ ! isLoading &&
					!! events?.others?.length &&
					events.others.map( ( { id, title, eventTime, category } ) => {
						const date = dayjs.unix( eventTime );
						return (
							<LinkBox
								key={ id }
								maxW={ [ 'full', 'sm' ] }
								p={ [ 2, 3, 4 ] }
								bg={ bg }
								borderWidth="1px"
								shadow="base"
								rounded="lg"
								transitionProperty="common"
								transitionDuration="normal"
								_hover={ {
									background: bgHover,
								} }
							>
								<Flex fontSize="xs" alignItems="center">
									{ date.format( 'DD.MM.YYYY' ) } &middot; { category }
								</Flex>

								<Heading size="md" mt="2">
									<LinkOverlay as={ NextLink } href={ `/event/${ id }` } passHref>
										{ title }
									</LinkOverlay>
								</Heading>
							</LinkBox>
						);
					} ) }
			</SimpleGrid>
		</>
	);
}
