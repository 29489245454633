import { signOut, useSession } from 'next-auth/react';
import {
	Box,
	Text,
	Button,
	useColorMode,
	Divider,
	Menu,
	MenuButton,
	MenuItemOption,
	MenuList,
	MenuOptionGroup,
	Portal,
} from '@chakra-ui/react';
import useLocalStorage from '../hooks/useLocalStorage';
import AppVersion from './AppVersion';

const Footer = () => {
	const { data: session } = useSession();
	const { colorMode, toggleColorMode } = useColorMode();

	const [ continuesScanningEnabled, setContinuesScanningEnabled ] = useLocalStorage(
		'continues-scanning-enabled',
		true
	);

	return (
		<>
			{ session && (
				<>
					<Divider mt="4" />
					<Menu>
						<MenuButton as={ Button } mt="4" variant="outline" w="100%">
							Einstellungen
						</MenuButton>
						<Portal>
							<MenuList maxWidth="85vw">
								<MenuOptionGroup
									title="Kontinuierliches Scannen"
									type="checkbox"
									value={ continuesScanningEnabled ? [ 'yes' ] : [] }
									onChange={ ( value ) => {
										setContinuesScanningEnabled( value.length ? true : false );
									} }
								>
									<MenuItemOption value="yes">
										Ticketdetails automatisch nach 3 Sekunden schliessen
									</MenuItemOption>
								</MenuOptionGroup>
								<MenuOptionGroup
									defaultValue="15"
									title="Erscheinungsbild"
									type="radio"
									value={ colorMode }
									onChange={ toggleColorMode }
								>
									<MenuItemOption value="light">Hell</MenuItemOption>
									<MenuItemOption value="dark">Dunkel</MenuItemOption>
								</MenuOptionGroup>
							</MenuList>
						</Portal>
					</Menu>
					<Box mt={ 4 }>
						<Text textAlign="center">Angemeldet als: { session?.user?.name }</Text>
						<Button
							variant="ghost"
							w="100%"
							mt={ 4 }
							onClick={ ( e ) => {
								e.preventDefault();
								signOut();
							} }
						>
							Abmelden
						</Button>
					</Box>
					<AppVersion />
				</>
			) }
		</>
	);
};

export default Footer;
