import { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import groupBy from 'lodash/groupBy';
import isToday from 'dayjs/plugin/isToday';

interface Event {
	id: number;
	title: string;
	eventTime: number;
	category: string;
}

interface GroupedEvents {
	others?: Event[];
	today?: Event[];
}

dayjs.extend( isToday );

const useEvents = () => {
	const [ isLoading, setIsLoading ] = useState( false );
	const [ events, setEvents ] = useState< GroupedEvents >( {} );

	useEffect( () => {
		const fetchData = async () => {
			try {
				setIsLoading( true );
				const response = await fetch( '/api/events' );
				const data = await response.json();
				if ( data?.error ) {
					throw new Error( data.error );
				}

				const groupedEvents = groupBy( data, ( event ) => {
					const date = dayjs.unix( event.eventTime );
					return date.isToday() ? 'today' : 'others';
				} );

				setEvents( groupedEvents );
			} catch ( error ) {
				// Do nothing
			} finally {
				setIsLoading( false );
			}
		};

		fetchData();
	}, [] );

	return { isLoading, events };
};

export default useEvents;
