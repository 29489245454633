import { Container, Flex, Box, useColorModeValue } from '@chakra-ui/react';
import Header from '../components/Header';
import EventSelector from '../components/EventSelector';
import Footer from '../components/Footer';
import Head from 'next/head';

export default function Page() {
	return (
		<Flex
			bg={ useColorModeValue( 'gray.50', 'inherit' ) }
			minH="100vh"
			py={ [ 4, 8, 12 ] }
			flexDirection="column"
		>
			<Head>
				<meta
					key="theme-color"
					name="theme-color"
					content={ useColorModeValue( '#f7fafc', '#1a202c' ) }
				/>
			</Head>
			<Header />
			<Container>
				<Box>
					<EventSelector />
				</Box>
				<Footer />
			</Container>
		</Flex>
	);
}

// Require authentication.
Page.auth = true;
