/**
 * External dependencies
 */
import { useEffect, useState } from 'react';

const STORAGE_PREFIX = 'kaufleuten:';

export default function useLocalStorage( key, initialValue ) {
	const storageKey = STORAGE_PREFIX + key;

	const [ storedValue, setStoredValue ] = useState( () => {
		try {
			const item = window.localStorage.getItem( storageKey );
			return item ? JSON.parse( item ) : initialValue;
		} catch ( error ) {
			return initialValue;
		}
	} );

	useEffect( () => {
		try {
			if ( storedValue === initialValue ) {
				window.localStorage.removeItem( storageKey );
			} else {
				window.localStorage.setItem( storageKey, JSON.stringify( storedValue ) );
			}
			window.dispatchEvent( new Event( 'local-storage' ) );
		} catch ( error ) {}
	}, [ initialValue, storageKey, storedValue ] );

	useEffect( () => {
		const handleStorageChange = ( event: StorageEvent | CustomEvent ) => {
			if ( ( event as StorageEvent )?.key && ( event as StorageEvent ).key !== storageKey ) {
				return;
			}

			const item = window.localStorage.getItem( storageKey );
			const parsedItem = item ? JSON.parse( item ) : initialValue;

			setStoredValue( parsedItem );
		};

		// Event for other tabs but not the current one.
		window.addEventListener( 'storage', handleStorageChange );
		// Event for the current tab.
		window.addEventListener( 'local-storage', handleStorageChange );

		return () => {
			window.removeEventListener( 'local-storage', handleStorageChange );
			window.removeEventListener( 'storage', handleStorageChange );
		};
	}, [ storageKey, initialValue ] );

	return [ storedValue, setStoredValue ];
}
